import React, { useState } from 'react'
import { graphql, Link } from 'gatsby'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import SEO from '../components/seo'
import { useLunr } from 'react-lunr'
import ProductTile from '../components/productTile';

export const query = graphql`
query($slug: String!){
    localSearchPages {
        index
        store
    }
    category: strapiCategory(slug: {eq: $slug}) {
        slug
        name
        products {
            slug
            name
            id
            category {
                name
            }
            images {
                localFile {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
            cover {
                localFile {
                    childImageSharp {
                        gatsbyImageData
                    }
                }
            }
        }
    }
    categories: allStrapiCategory {
        nodes {
            name
            slug
        }
    }
  }
`
export default function CategoryTemplate({ data }) {
    const category = data.category

    const index = data.localSearchPages.index
    const store = data.localSearchPages.store
    const [searchQuery, setSearchQuery] = useState(null)
    const results = useLunr(searchQuery, index, store) // use lunr engine to search

    const products = searchQuery ? results : data.category.products
    const categories = data.categories.nodes

    return (
        <Layout>
            <SEO
                title={category.name}
            />
            <PageHeader
                title={category.name}
                subTitle="Check out our catalog and get inspiration for some creative & innovative design ideas for your door, wardrobe, and other furniture. Kick-start your build with us!"
            />
            <div className="container mx-auto">
                <div className="w-full lg:w-3/4 mx-auto flex flex-col gap-4 lg:gap-0 lg:flex-row items-start justify-center">
                    <div className="w-full lg:w-1/2 flex gap-y-2 gap-x-4 flex-wrap">
                        {categories.map(category => {
                            return (
                                <Link
                                    to={"/inspiration/" + category.slug}
                                    activeClassName="bg-primary text-white"
                                    className="text-center py-2 md:py-1 w-auto px-2 mt-2 text-xs rounded-full border  border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-none focus:ring-0 active:bg-primary active:text-white active:shadow-lg transition duration-150 ease-in-out "
                                >
                                    {category.name}
                                </Link>
                            )
                        })}
                        <Link
                            to="/inspiration"
                            activeClassName="bg-primary text-white"
                            className="text-center py-2 md:py-1 w-[93px] mt-2 text-xs rounded-full border border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-none focus:ring-0 active:bg-primary active:text-white active:shadow-lg transition duration-150 ease-in-out "
                        >
                            All
                        </Link>
                        <Link
                            to="/saved"
                            activeClassName="bg-primary text-white"
                            className="text-center py-2 md:py-1 w-[93px] mt-2 text-xs rounded-full border border-primary hover:bg-primary hover:text-white focus:bg-primary focus:text-white focus:outline-none focus:ring-0 active:bg-primary active:text-white active:shadow-lg transition duration-150 ease-in-out "
                        >
                            Liked
                        </Link>
                    </div>
                    <input
                        type="text"
                        className='w-full lg:w-1/2 rounded-md border border-primary py-2 px-4 bg-[#C4C4C4] focus:outline-none'
                        placeholder='Search'
                        value={searchQuery}
                        onChange={e => setSearchQuery(e.target.value)}
                    />
                </div>
                <div className="my-6 w-full grid lg:grid-cols-3 gap-6 lg:gap-12">
                    {products.map(product => <ProductTile product={product} />)}
                </div>
            </div>
        </Layout>
    )
}
